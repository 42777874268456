import styled from 'styled-components'

export const StyledSpinner = styled.div`
  position: fixed;
  top: calc((100vh - 170px) / 2);
  left: calc((100vw - 150px) / 2);
  width: auto;
  text-align: center;
  z-index: 10;

  .C-Spinner {
    border-color: #c0ff00;
    border-right-color: #f2ffcc;
    font-size: 60px !important;
    border-radius: 30px;
  }

  .loading_background {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
  }

  @keyframes roundelLG {
    0% {
      background-color: #fffd4e;
    }
    100% {
      background-color: #fb2ff9;
    }
  }

  @keyframes roundelMD {
    0% {
      background-color: #22ca7d;
      transform: rotate(0deg);
      top: 15px;
      left: 25px;
    }
    50% {
      transform: rotate(180deg);
      background-color: #8530f6;
      top: 5px;
      left: 5px;
    }
    100% {
      background-color: #22ca7d;
      transform: rotate(0deg);
      top: 15px;
      left: 25px;
    }
  }

  @keyframes roundelSM {
    0% {
      background-color: #22ca7d;
      transform: rotate(0deg);
      top: 5px;
      left: 5px;
    }
    50% {
      transform: rotate(180deg);
      background-color: #8530f6;
      top: 15px;
      left: 25px;
    }
    100% {
      background-color: #22ca7d;
      transform: rotate(0deg);
      top: 5px;
      left: 5px;
    }
  }

  .Roundel__Moving {
    position: relative;
    height: 150px;
    width: 150px;
  }

  .Roundel__Moving--lg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fffd4e;
    border-radius: 100px;
    filter: blur(4px);
    z-index: 2;
    animation: roundelLG linear 2s infinite alternate;
  }

  .Roundel__Moving--md {
    position: absolute;
    top: 5px;
    left: 10px;
    height: 80%;
    width: 75%;
    background: #22ca7d;
    border-radius: 100px;
    filter: blur(12px);
    z-index: 3;
    transform: rotate(0deg);
    animation: roundelMD linear 5s infinite;
  }

  .Roundel__Moving--sm {
    position: absolute;
    top: 5px;
    left: 10px;
    height: 70%;
    width: 0%;
    background: #22ca7d;
    border-radius: 100px;
    filter: blur(12px);
    z-index: 4;
    transform: rotate(0deg);
    animation: roundelMD linear 6s infinite;
  }
`
