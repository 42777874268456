import { PLEASE_WAIT } from '../../constants'
import { StyledSpinner } from './styles'
import Loading from '../../assets/Roundel_Logo_Loading.png'

const Spinner: React.FC = () => {
  return (
    <StyledSpinner>
      <div className="loading_background"></div>
      <div>
        {' '}
        <img src={Loading} alt="Loading" className="min-logo-size"></img>
      </div>
      <div>{PLEASE_WAIT}</div>
    </StyledSpinner>
  )
}

export default Spinner
