import { isTargetDomain } from '@praxis/component-auth'
import axios from 'axios'
import { useEffect } from 'react'

let interceptor: number | null = null
const API_KEY_HEADER = 'x-api-key'

const ApiKeyInterceptor = (apiConfig: ApiConfig) => {
  useEffect(() => {
    interceptor = axios.interceptors.request.use((req) => {
      req.headers = req.headers ?? {}
      if (!Object.prototype.hasOwnProperty.call(req.headers, API_KEY_HEADER)) {
        if (isTargetDomain(req.url || '')) {
          req.headers[API_KEY_HEADER] = apiConfig.apiKey || ''
        }
      } else if (!req.headers[API_KEY_HEADER]) {
        delete req.headers[API_KEY_HEADER]
      }
      return req
    })
    return () => {
      interceptor && axios.interceptors.request.eject(interceptor)
    }
  }, [apiConfig.apiKey])
  return null
}

export default ApiKeyInterceptor
