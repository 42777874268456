import axios from 'axios'
import { buildURL } from '../helper'
import getApiConfig from '../../config/apiConfig'

const getUserType = async () => {
  const apiConfig = await getApiConfig()
  const { data } = await axios.get(
    `${buildURL('userInfo', apiConfig.campaign)}`,
  )
  return data
}

export default getUserType
