import { Layout } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'

interface Props {
  isSideNavMinimized?: boolean
}

export const FeedbackBlock = styled.div`
  .openDrawer {
    position: fixed;
    bottom: 0;
    right: 1%;
    text-transform: capitalize;
    font-weight: bold;
    background: #c0ff00 !important;
    border-color: #c0ff00 !important;
    color: #000 !important;
  }
  .C-Drawer {
    height: 42em;
    width: 31em;
    top: 26% !important;
    right: 1% !important;
    transform: translateY(0);
    .C-Input--type-info.isErrored {
      display: none;
    }
  }

  .hc-container > .C-GridContainer {
    background: #e8e3e0;
    padding: 2em;
  }
`

export const StyledLayoutBody = styled(Layout.Body)`
  .C-SideNav {
    margin-top: 64px;
    z-index: 4;
    width: 257px;
    left: 0;
  }

  .second_column {
    padding-top: 0 !important;
  }

  .first_column {
    max-width: ${(props: Props) => {
      const { isSideNavMinimized } = props
      return isSideNavMinimized ? '56px' : '257px'
    }} !important;
    transition: max-width 0.3s;
    flex-basis: ${(props: Props) => {
      const { isSideNavMinimized } = props
      return isSideNavMinimized ? '56px' : '257px'
    }} !important;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: ${(props: Props) => {
      const { isSideNavMinimized } = props
      return isSideNavMinimized ? 'calc(100% - 56px)' : 'calc(100% - 257px)'
    }} !important;
    background: #f7f7f7;
    margin: -12px;
    z-index: 5 !important;
    padding-top: 0;
  }

  .openDrawer {
    text-transform: capitalize;
    font-weight: bold;
    background: #c0ff00 !important;
    border-color: #c0ff00 !important;
    color: #000 !important;
  }
`
