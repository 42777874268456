import { Modal, Grid, Button } from '@enterprise-ui/canvas-ui-react'
import { OK } from '../../constants'

const GenericErrorModal = (props: any) => {
  const { title = '', message = '' } = props
  return (
    <>
      <Modal
        headingText={title}
        isVisible
        onRefuse={() => {
          window.location.href = '/views'
        }}
        className="hc-pa-normal hc-z-index"
      >
        <div className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <p>{message}</p>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="normal">
            <Grid.Item>
              <Button
                onClick={() => {
                  window.location.href = '/views'
                }}
                type="primary"
              >
                {OK}
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}

export default GenericErrorModal
