import { useQuery } from '@tanstack/react-query'
import { GET_USER_TYPE } from '../constants'
import getUserType from '../api/user'

const useUserType = () => {
  return useQuery([GET_USER_TYPE], () => {
    return getUserType()
  })
}

export default useUserType
