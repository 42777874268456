import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import {
  useIsFetching,
  useIsMutating,
  useQueryClient,
} from '@tanstack/react-query'
import {
  Anchor,
  Button,
  GridContainer,
  GridItem,
  Modal,
} from '@enterprise-ui/canvas-ui-react'
import { useCallback, useEffect, useState } from 'react'
import { trackLogOut } from '../../common/fireflyEvents'
import {
  getUserSpecificConfig,
  isLoggedInAsInternalUser,
  isPOLDomain,
  isRmsUser,
  isTargetPlusManagedServiceUser,
  removeLocalStorageItems,
} from '../../common/helper'
import { SIGN_OUT } from '../../constants'
import { StyledForbiddenModal } from './styles'
import {
  POL_DEV_SUPPORT_LINK,
  POL_PROD_SUPPORT_LINK,
} from '../../config/appConfig'

const ForbiddenModal = () => {
  const queryClient = useQueryClient()
  const auth = useAuth()
  const apiConfig = getUserSpecificConfig(useEnv())
  const isLoading = useIsMutating() + useIsFetching()
  const [supportURL, setSupportURL] = useState<string>('')

  const signOut = useCallback(() => {
    trackLogOut()
    removeLocalStorageItems(['selected_vendor', 'external_vendor_detail'])
    auth.logout()
    queryClient.clear()
  }, [auth, queryClient])

  const domain =
    apiConfig.appEnv === 'prod' ? POL_PROD_SUPPORT_LINK : POL_DEV_SUPPORT_LINK

  const getRmsURL = () => {
    if (isPOLDomain(window.location.origin)) {
      return apiConfig?.rmsPolURL
    } else {
      return apiConfig?.rmsEnterpriseURL
    }
  }

  useEffect(() => {
    if (Object.keys(apiConfig).length > 1) {
      let supportPath
      //check if the user is target+ managed service user
      //check if he is not part of RMS
      if (isRmsUser(apiConfig)) {
        supportPath = `${getRmsURL()}${apiConfig?.supportURLs?.RMS?.ticket}`
      } else if (isTargetPlusManagedServiceUser(apiConfig)) {
        supportPath = `mailto:Roundel-Kiosk-Support@target.com`
      } else {
        supportPath = `${domain}${apiConfig?.supportURLs?.POL?.ticket}`
      }
      setSupportURL(supportPath)
    }
  }, [apiConfig])

  return (
    <>
      {' '}
      {!isLoading && (
        <StyledForbiddenModal>
          <Modal headingText="Data not available" isVisible>
            <div className="hc-pa-normal hc-pa-lg  hc-fs-md">
              {isLoggedInAsInternalUser() ? (
                <>
                  <div>
                    There is no Roundel campaign data associated to this
                    account. If you have followed all the necessary steps
                    mentioned in the{' '}
                    <Anchor
                      href="https://confluence.target.com/display/TMN/Kiosk+Documentation#KioskDocumentation-GettingStarted"
                      target="_blank"
                    >
                      Getting Started guide for Kiosk
                    </Anchor>{' '}
                    and you are still seeing no data, please reach out to us on
                    our{' '}
                    <Anchor
                      href="https://target.slack.com/archives/C02TUCVQNDN"
                      target="_blank"
                    >
                      slack channel.
                    </Anchor>
                  </div>
                </>
              ) : (
                <>
                  {isTargetPlusManagedServiceUser(apiConfig) &&
                  !isRmsUser(apiConfig) ? (
                    <>
                      <div>
                        There is no Roundel campaign data associated to this
                        account. If you believe this is an error, please reach
                        out to{' '}
                        <Anchor
                          href="mailto:Roundel-Kiosk-Support@target.com"
                          target="_blank"
                        >
                          Roundel-Kiosk-Support@target.com
                        </Anchor>{' '}
                        with the following details for assistance:
                      </div>
                      <ul>
                        <li>Name</li>
                        <li>Email</li>
                        <li>Advertiser Name</li>
                        <li>
                          Question or description for the topic of assistance
                        </li>
                      </ul>
                      <div>Thank you!</div>
                    </>
                  ) : (
                    <>
                      {isRmsUser(apiConfig) ? (
                        <>
                          <div>
                            There is no Roundel campaign data associated to this
                            account. If you believe this is an error,{' '}
                            <Anchor href={supportURL} target="_blank">
                              please submit a support ticket here.
                            </Anchor>
                          </div>
                          <br />
                          <div>
                            To learn more about running digital media campaigns
                            with Roundel reach out to your Roundel
                            representative or learn more at{' '}
                            <Anchor href="https://roundel.com/" target="_blank">
                              www.Roundel.com
                            </Anchor>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            There is no Roundel campaign data associated with
                            this account. If you believe this is an error,
                            please submit a support ticket by searching and
                            selecting <strong>"(RIK) Roundel Kiosk"</strong>{' '}
                            within{' '}
                            <Anchor
                              href="https://www.partnersonline.com/page/help"
                              target="_blank"
                            >
                              POL's Help Page
                            </Anchor>
                            .
                          </div>
                          <br />
                          <div>
                            To learn more about running digital media campaigns
                            with Roundel, reach out to your Roundel
                            representative or learn more at{' '}
                            <Anchor href="https://roundel.com/" target="_blank">
                              www.Roundel.com
                            </Anchor>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <GridContainer direction="row-reverse" spacing="dense">
                <GridItem>
                  <Button
                    type="primary"
                    data-test="sign-out"
                    onClick={() => {
                      signOut()
                    }}
                  >
                    {SIGN_OUT}
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          </Modal>
        </StyledForbiddenModal>
      )}
    </>
  )
}

export default ForbiddenModal
