import moment from 'moment'
import { getDateFilters } from '../common/Metrics'

export const buildURL = <T extends string>(
  key: T,
  config?: RIKApi<T>,
): string => {
  return config && config.paths ? `${config.host}${config.paths[key]}` : ''
}

/* converts valid string date in MM/DD/YYYY to YYYY-MM-DD*/
export const formatDateString = (date?: string) => {
  if (date && date?.length > 9) {
    return moment(date).format('YYYY-MM-DD')
  }
  return null
}

export const formatDateforDatePicker = (dateVal: string) => {
  if (dateVal === '') {
    return ''
  }

  return moment(dateVal).format('YYYY/MM/DD')
}

export const FormatDataResponse = (type: string, date?: string) => {
  return type === 'date' ? moment(date).format('YYYY-MM-DD') : date
}

export const convertPercent = (value: string) => {
  return (Number(value) / 100).toFixed(4)
}

export const formatFilters = (
  filters: Filter[] = [],
  dimensions: Record<string, any>,
  metricsData: Record<string, any>,
) => {
  const dateFilterList = getDateFilters(dimensions)

  return filters.map((filter) => {
    const { field, operator, value, value2, values, date_selector } = filter
    let val: string | null = value
    let val2: string | undefined | null = value2
    let listValues: any[] = []
    if (dateFilterList.includes(field)) {
      val = formatDateString(value)
      if (values && values.length > 0) {
        values.forEach((item) => {
          listValues.push(formatDateString(item))
        })
      }
      if (value2) {
        val2 = formatDateString(value2)
      }
    } else {
      if (values && values.length > 0) {
        values.forEach((item) => {
          listValues.push(item)
        })
      }
    }
    if (
      ['percentageWithDecimal', 'percentage'].includes(
        metricsData[field]?.format,
      )
    ) {
      val = convertPercent(value)
      if (value2) {
        val2 = convertPercent(value2)
      }
    }
    return {
      field,
      operator,
      value: val,
      value2: val2,
      values: listValues,
      date_selector,
    }
  })
}

export const convertArrayAsString = (value: string[] | Filter[] = []) => {
  return value.join(',')
}

export const isItemLevelFilter = (filter: string) => {
  const itemLevelFilters = [
    'TCIN',
    'ITEM_DIVISION',
    'ITEM_DEPARTMENT',
    'ITEM_CLASS',
    'ITEM_SUB_CLASS',
    'ITEM_BRAND',
  ]
  return itemLevelFilters.includes(filter)
}
