/* Routes */
const DASHBOARD_PATH = '/'
const VIEW_PATH = '/view'
const SAVED_VIEWS_PATH = '/views'
const TEMPLATES_PATH = '/templates'
const SCHEDULED_REPORTS_PATH = '/scheduledReports'
const METRICS_MANAGER_PATH = '/manageMetrics'
const ADMIN_PATH = '/admin'
const TERMS_OF_USER_PATH = '/termsOfUse'

export {
  DASHBOARD_PATH,
  VIEW_PATH,
  SAVED_VIEWS_PATH,
  TEMPLATES_PATH,
  SCHEDULED_REPORTS_PATH,
  METRICS_MANAGER_PATH,
  ADMIN_PATH,
  TERMS_OF_USER_PATH,
}
