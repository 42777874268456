import { useAuth } from '@praxis/component-auth'
import { ErrorBoundary, LogLevel } from '@praxis/component-logging'
import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import { Layout, Button, Drawer, Grid } from '@enterprise-ui/canvas-ui-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { trackLogOut } from '../common/fireflyEvents'
import {
  getExternalVendorDetails,
  getLoggedInUserId,
  getSelectedAccount,
  getUserEmail,
  isAdmin,
  isLoggedInAsInternalUser,
  isPOLDomain,
  isPOLSessionActive,
  isRmsUser,
  removeLocalStorageItems,
} from '../common/helper'
import FallbackComponent from '../components/ErrorBoundary/FallBackScreen'
import TimeoutScreen from '../components/ErrorBoundary/TimeoutScreen'
import Header from '../components/Header'
import Spinner from '../components/Spinner'
import Routes from '../routes'
import UniversalAlert from '../components/UniversalAlert'
import { useState, useEffect } from 'react'
import useGetUniversalStatus from '../domain/useUniversalStatus'
import { FeedbackBlock, StyledLayoutBody } from './style'
import FeedbackForm from '../components/Header/FeedbackForm'
import Toast from '../components/Toast'
import { trackEvent } from '../common/fireflyHelper'
import { SideNav } from '@dale/sidenav'
import useUserType from '../domain/useUserType'
import {
  DashboardIcon,
  StarIcon,
  ClockIcon,
  CalculationIcon,
  TroubleshootIcon,
  DataTableIcon,
  PlusCircleIcon,
  DatabaseIcon,
} from '@enterprise-ui/icons'
import { Footer } from '@dale/footer'
import {
  POL_DEV_SUPPORT_LINK,
  POL_PROD_SUPPORT_LINK,
  TERMS_OF_USE_DEFAULT_LINK,
} from '../config/appConfig'
import { log } from '../common/log'

interface Props {
  apiConfig: any
}

const DefaultLayout = (props: Props) => {
  const { apiConfig } = props
  const history = useNavigate()
  const [hideAlert, setHideAlert] = useState<boolean>(false)
  const [isSideNavMinimized, setIsSideNavMinimized] = useState<boolean>(false)
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const auth = useAuth()
  const location = useLocation()
  const isAuthenticated = auth.isAuthenticated()
  const { data: userDetails } = useUserType()
  const { user_type } = userDetails || {}
  const [isFeedbackFormVisible, setFeedbackFormVisibility] =
    useState<boolean>(false)

  const { data: alertData } = useGetUniversalStatus('UNIVERSAL_ALERT')
  const [feedbackToast, showFeedbackSubmitToast] = useState<string>('')

  let alertMsg = alertData?.params?.status ? alertData?.content : ''

  if (!isAuthenticated) {
    removeLocalStorageItems([
      'userDir',
      'userADGroups',
      'selected_vendor',
      'external_vendor_detail',
    ])
  }

  const callListenerForCompanyChange = () => {
    document.addEventListener('visibilitychange', function () {
      if (!document.hidden) {
        const { session } = auth
        const userId = session?.userInfo?.lanId || ''
        const isActiveSession = isPOLSessionActive(userId)
        if (!isActiveSession) {
          trackLogOut()
          removeLocalStorageItems(['selected_vendor', 'external_vendor_detail'])
          auth.logout()
        }
      }
    })
  }

  const handleClick = () => {
    setHideAlert(true)
  }

  callListenerForCompanyChange()

  useEffect(() => {
    showFeedbackSubmitToast('')
  }, [location])

  const isNavSelected = (path: string) => {
    return location.pathname === path
  }

  const newReportRedirect = () => {
    trackEvent('New Report - Click')
    history('/view')
  }

  const getRmsURL = () => {
    if (isPOLDomain(window.location.origin)) {
      return apiConfig?.rmsPolURL
    } else {
      return apiConfig?.rmsEnterpriseURL
    }
  }

  const termsOfUseLink = () => {
    if (user_type === 'EXTERNAL_TARGET_PLUS') {
      return `${window.location.origin}/termsOfUse`
    } else if (isRmsUser(apiConfig)) {
      return `${getRmsURL()}${apiConfig?.supportURLs?.RMS?.termsOfUseLink}`
    } else {
      const domain =
        apiConfig.appEnv === 'prod'
          ? POL_PROD_SUPPORT_LINK
          : POL_DEV_SUPPORT_LINK
      const termsOfuse =
        apiConfig?.supportURLs?.POL?.termsOfUseLink || TERMS_OF_USE_DEFAULT_LINK
      return `${domain}${termsOfuse}`
    }
  }

  const btnStyles = {
    border: '1px solid',
    marginTop: '10px',
    marginBottom: '10px',
    borderRadius: '8px',
    height: '36px',
  }

  let sideNavMenuList = [
    {
      label: 'New Report',
      selected: false,
      icon: PlusCircleIcon,
      as: Button,
      otherProps: {
        type: 'secondary',
        style: btnStyles,
        onClick: newReportRedirect,
      },
    },
    {
      label: 'Dashboard',
      to: '/',
      selected: isNavSelected('/'),
      icon: DashboardIcon,
      as: Link,
      isReactLink: true,
    },
    {
      label: 'Saved Views',
      to: '/views',
      selected: isNavSelected('/views'),
      icon: StarIcon,
      as: Link,
      isReactLink: true,
    },
    {
      label: 'Templates',
      to: '/templates',
      selected: isNavSelected('/templates'),
      icon: DataTableIcon,
      as: Link,
      isReactLink: true,
    },
    {
      label: 'Scheduled Reports',
      to: '/scheduledReports',
      selected: isNavSelected('/scheduledReports'),
      icon: ClockIcon,
      as: Link,
      isReactLink: true,
    },
    {
      label: 'Metrics and Dimensions',
      to: '/manageMetrics',
      selected: isNavSelected('/manageMetrics'),
      icon: CalculationIcon,
      as: Link,
      isReactLink: true,
    },
  ]

  if (isLoggedInAsInternalUser()) {
    sideNavMenuList.push({
      label: isAdmin(apiConfig) ? 'Admin' : 'Mapping Info',
      to: '/admin',
      selected: isNavSelected('/admin'),
      icon: isAdmin(apiConfig) ? TroubleshootIcon : DatabaseIcon,
      as: Link,
      isReactLink: true,
    })
  }

  return (
    <ErrorBoundary
      key={location.pathname}
      FallbackComponent={(errorObject: any) => {
        const errorMessage = errorObject?.error?.message

        let selectedAcct: SelectOption
        if (user_type === 'INTERNAL') {
          selectedAcct = getSelectedAccount()
        } else {
          selectedAcct = getExternalVendorDetails()
        }

        const finalErrorMessage = `Kiosk UI Error = ${errorMessage}, for the user ${getLoggedInUserId()} (${getUserEmail()}). Selected account is ${selectedAcct?.id} (${selectedAcct?.value}).`
        log.error({ message: finalErrorMessage })

        if (LogLevel.Info) {
          return (
            <FallbackComponent userDetails={userDetails}></FallbackComponent>
          )
        } else if (LogLevel.Error) {
          return <TimeoutScreen userDetails={userDetails}></TimeoutScreen>
        } else {
          return (
            <FallbackComponent userDetails={userDetails}></FallbackComponent>
          )
        }
      }}
    >
      {/* New header v2 code */}
      <Layout theme="roundel" fullWidth={true} darkMode={false}>
        {isAuthenticated && <Header userDetails={userDetails} />}
        {isAuthenticated && alertMsg && !hideAlert && (
          <UniversalAlert data={alertMsg} handleClick={handleClick} />
        )}

        <StyledLayoutBody
          includeRail={true}
          isSideNavMinimized={isSideNavMinimized}
        >
          <Grid.Container justify="center">
            <Grid.Item xs={2} className="first_column">
              {isAuthenticated && (
                <SideNav
                  items={sideNavMenuList}
                  settings={{
                    canMinimize: true,
                    hasOverlay: false,
                    isMinimized: isSideNavMinimized,
                    onMinimizeChange: (isMinimized: any) => {
                      setIsSideNavMinimized(isMinimized)
                    },
                  }}
                />
              )}
            </Grid.Item>
            <Grid.Item xs className="second_column">
              <Routes userDetails={userDetails} />
              {isAuthenticated && (
                <Footer termAndConditionsUrl={termsOfUseLink()}>
                  <Button
                    className="openDrawer"
                    type="primary"
                    onClick={() => {
                      setFeedbackFormVisibility(true)
                      showFeedbackSubmitToast('')
                    }}
                  >
                    Feedback
                  </Button>
                </Footer>
              )}

              {
                <FeedbackBlock>
                  <Drawer
                    isVisible={isFeedbackFormVisible}
                    onRequestClose={() => {
                      setFeedbackFormVisibility(false)
                      showFeedbackSubmitToast('')
                    }}
                    headingText="How would you like to rate us?"
                  >
                    <FeedbackForm
                      isVisible={isFeedbackFormVisible}
                      onCancel={() => {
                        setFeedbackFormVisibility(false)
                      }}
                      onSubmit={(type: string) => {
                        showFeedbackSubmitToast(type)
                        trackEvent(`Layout- Feedback Submit`, {
                          key: `Feedback Form`,
                          module: 'Layout',
                        })
                      }}
                      from={'popup'}
                    />
                  </Drawer>
                </FeedbackBlock>
              }
              {feedbackToast === 'success' && (
                <Toast
                  type="success"
                  message="Feedback submitted successfully."
                />
              )}
              {feedbackToast === 'error' && (
                <Toast
                  type="error"
                  message="Something went wrong! Please try again."
                />
              )}
            </Grid.Item>
          </Grid.Container>
        </StyledLayoutBody>
      </Layout>
      {/* End of New header v2 code */}
      {isFetching + isMutating > 0 && <Spinner />}
    </ErrorBoundary>
  )
}

export default DefaultLayout
