import { VENDOR } from '../constants'
import { useMemo } from 'react'
import useCampaignSearch from '../domain/useCampaignSearch'
import { getCurrentBpId } from '../common/helper'

const useVendorOptions = (isCurrentBpIdRequired?: boolean) => {
  const { data = [], isError } = useCampaignSearch(VENDOR)
  const currentBpId = getCurrentBpId()
  return useMemo(() => {
    if (isError) {
      return []
    }
    const [{ vendors = [] } = {}] = data
    return vendors.reduce((options: [any], account: any) => {
      const { vendor_name, bp_id, target_plus } = account
      // if (isCurrentBpIdRequired || currentBpId !== bp_id) {
      options.push({
        label: vendor_name,
        value: vendor_name,
        id: bp_id,
        isTargetPlusAccount: target_plus,
      })
      // }
      return options
    }, [])
  }, [data, isError, currentBpId, isCurrentBpIdRequired])
}

export default useVendorOptions
