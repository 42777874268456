import { useAuth } from '@praxis/component-auth'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { removeLocalStorageItems } from '../../common/helper'
import ForbiddenModal from '../ForbiddenModal'
import GenericErrorModal from '../GenericErrorModal'
import {
  INVALID_VIEW_ACCESS_TITLE,
  INVALID_VIEW_ACCESS_MSG,
} from '../../constants'

let interceptor: number | null = null
const AuthErrorInterceptor = () => {
  const auth = useAuth()

  const signOut = useCallback(() => {
    removeLocalStorageItems(['selected_vendor', 'external_vendor_detail'])
    auth.logout()
  }, [auth])

  const [isForbidden, setForbidden] = useState(false)
  const [isGenericError, setGenericError] = useState(false)
  useEffect(() => {
    interceptor = axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const status = error?.response?.status
        const errorCode = error?.response?.data?.code
        if (status === 401) {
          signOut()
        } else if (status === 403 && errorCode !== 'NOT_AUTHORIZED_TO_UNLOCK') {
          setForbidden(true)
        } else if (status === 406) {
          setGenericError(true)
        }

        return Promise.reject(error)
      },
    )
    return () => {
      interceptor && axios.interceptors.response.eject(interceptor)
    }
  }, [signOut])

  return (
    <>
      {isForbidden && <ForbiddenModal />}
      {isGenericError && (
        <GenericErrorModal
          title={INVALID_VIEW_ACCESS_TITLE}
          message={INVALID_VIEW_ACCESS_MSG}
        />
      )}{' '}
    </>
  )
}

export default AuthErrorInterceptor
