const styles = `
    .hc-fw-bold {
        font-weight: bold !important;
    }
    
    .hc-bb-min {
        border-bottom: 2px solid !important;
    }

    .hc-bb-xs {
        border-bottom: 1px solid #b8b8b8 !important;
    }

    .hc-bt-xs {
        border-top: 1px solid #b8b8b8 !important;
    }

    .hc-ba-min {
        border: 2px solid !important;
    }

    .hc-txt-italic {
      font-style: italic
    }

    .hc-txt-center {
        text-align: center;
    }

    .hc-txt-right {
        text-align: right;
    }

    .hc-td-underline {
        text-decoration: underline !important;
    }

    .C-Table .C-TableRow {
        padding-left: 0 !important;
    }

    .C-FormField__FieldInfo {
        min-height: auto;
        padding-bottom: 5px;
    }

    .C-Autocomplete__List .C-Button__innerContent {
        justify-content:left;
    }

    .C-Modal__scrollContainer {
        overflow:hidden;
    }

    .min-logo-size{
        width: 124px;
    }

    .hc-mh-neg {
        margin: 0 -12px;
    }
`
export default styles
