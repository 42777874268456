import { useMutation } from '@tanstack/react-query'
import saveFeedback from '../api/feedback/saveFeedback'

const useSaveFeedback = () => {
  return useMutation(async (reqObj: any) => {
    return await saveFeedback(reqObj)
  })
}

export default useSaveFeedback
