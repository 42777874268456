import { ProtectedElement } from '@praxis/component-auth'
import { lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import {
  DASHBOARD_PATH,
  METRICS_MANAGER_PATH,
  SAVED_VIEWS_PATH,
  SCHEDULED_REPORTS_PATH,
  TEMPLATES_PATH,
  VIEW_PATH,
  ADMIN_PATH,
  TERMS_OF_USER_PATH,
} from '../constants'

const Dashboard = lazy(() => import('../features/Dashboard/wrapper'))
const View = lazy(() => import('../features/View'))
const SavedViews = lazy(() => import('../features/SavedViews'))
const Templates = lazy(() => import('../features/Templates'))
const ScheduledReports = lazy(() => import('../features/ScheduledReports'))
const MetricsManager = lazy(() => import('../features/MetricsManager'))
const Admin = lazy(() => import('../features/Admin'))
const TermsOfUse = lazy(() => import('../features/TermsOfUse'))

interface Props {
  userDetails: any
}

const NewRoutes = (props: Props) => {
  const { userDetails } = props
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<ProtectedElement />}>
          <Route path={`${VIEW_PATH}/:id/:source`} element={<View />} />
          <Route path={`${VIEW_PATH}/:id`} element={<View />} />
          <Route path={VIEW_PATH} element={<View />} />
          <Route path={DASHBOARD_PATH} element={<Dashboard />} />
          <Route path={`/b/:bpid`} element={<Dashboard />} />
          <Route path={`/v/:vid`} element={<Dashboard />} />
          <Route path={SAVED_VIEWS_PATH} element={<SavedViews />} />
          <Route path={TEMPLATES_PATH} element={<Templates />} />
          <Route
            path={SCHEDULED_REPORTS_PATH}
            element={<ScheduledReports userDetails={userDetails} />}
          />
          <Route path={METRICS_MANAGER_PATH} element={<MetricsManager />} />
          <Route path={ADMIN_PATH} element={<Admin />} />
          <Route path={TERMS_OF_USER_PATH} element={<TermsOfUse />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default NewRoutes
