/*
👋 “Hey” from the Praxis team
Praxis ships with support for modern browsers, but you may have users or external vendors that require IE11.
If that’s the case - uncomment the below imports.
Learn more about our the default browser support in the README.

import '@praxis/react-app-polyfill/ie11'
import '@praxis/react-app-polyfill/stable'
*/

//import ReactDOM from 'react-dom'
import { onError, reportWebVitals } from '@praxis/component-logging'
import ReactDOM from 'react-dom/client'
import App from './app'
import * as serviceWorker from './serviceWorker'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
})

// If you want to start measuring performance in your app, enable the reportWebVitals()
// function below. Learn more: https://praxis.prod.target.com/components/logging#quick-start-metrics
reportWebVitals()
const container: any = document.getElementById('root')
ReactDOM.createRoot(container).render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
