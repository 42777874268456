//export * from './configuration'
export * from './alertMessages'
export * from './queryKeys'
export * from './routes'

/* Commons */
export const PLEASE_WAIT = 'Please Wait...'
export const CANCEL = 'Cancel'
export const SUBMIT = 'Submit'
export const APPLY = 'Apply'
export const APPLY_UPDATE = 'Apply Update'
export const OPEN = 'Open'
export const CLONE = 'Clone'
export const OK = 'OK'
export const DUPLICATE = 'Duplicate'
export const SHARE = 'Share'
export const SET_AS_DEFAULT = 'Set As Default'
export const DELETE = 'Delete'
export const ACCOUNT = 'ACCOUNT'
export const METRICS = 'Metrics'
export const CAMPAIGN = 'CAMPAIGN'
export const BRAND = 'BRAND'
export const VENDOR = 'VENDOR'
export const DIMENSIONS = 'Dimensions'
export const DIMENSION = 'Dimension'
export const CONFIRM = 'Confirm'
export const BTN_CANCEL = 'Cancel'
export const BTN_DELETE = 'Delete'
export const BTN_LOCK = 'Lock'
export const BTN_UNLOCK = 'Unlock'
export const CLEAR = 'Clear'
export const SAVE = 'Save'
export const SEND = 'Send'
export const UPDATE_VIEW = 'Update View'

/* Report Types */
export const PACING_PERFORMANCE = 'PACING_PERFORMANCE'
export const MODEL_ENABLEMENT = 'MODEL_ENABLEMENT'
export const CAMPAIGN_DAY_LEVEL = 'CAMPAIGN_DAY_LEVEL'
export const CAMPAIGN_TOTAL_LEVEL = 'CAMPAIGN_TOTAL_LEVEL'
export const CAMPAIGN_REPORT_LEVEL = 'CAMPAIGN_REPORT_LEVEL'
export const CAMPAIGN_ITEM_LEVEL = 'CAMPAIGN_ITEM_LEVEL'
export const ITEM_SALES = 'ITEM_SALES'
export const MEDIA_BENCHMARKS = 'MEDIA_BENCHMARKS'
export const LIVE_CAMPAIGN_HEALTH_CHECK = 'LIVE_CAMPAIGN_HEALTH_CHECK'
export const SEARCH_ACTIVITY = 'SEARCH_ACTIVITY'
export const SEARCH_KEYWORD_LEVEL = 'SEARCH_KEYWORD_LEVEL'
export const REPORT = 'REPORT'
export const TABULAR = 'TABULAR'

/* Header Component */
export const DASHBOARD = 'Dashboard'
export const SAVED_VIEWS = 'Saved Views'
export const ALL_VIEWS = 'All Views'
export const MY_VIEWS = 'My Views'
export const TEMPLATES = 'Templates'
export const SCHEDULED_REPORTS = 'Scheduled Reports'
export const METRICS_MANAGER_LINK = 'Metrics & Dimensions'
export const NEW_REPORT = 'New Report'
export const SIGN_OUT = 'Sign Out'
export const CURRENT_ACCOUNT = 'Current Account'
export const SWITCH_ACCOUNT = 'Switch Account'
export const USER_NAME = 'User Name'
export const COMPANY_NAME = 'Company Name'
export const REF_GUIDE = 'Training & Support'
export const ADMIN = 'Admin'
export const TERMS_OF_USE = 'Terms of Use'
export const FEEDBACK = 'Feedback'

/* Dashboard Page */
export const LIVE_CAMPAIGNS = 'Live Campaigns'
export const CAMPAIGN_NAME = 'Campaign Name'
export const CAMPAIGN_START_DATE = 'Campaign Start Date'
export const CAMPAIGN_END_DATE = 'Campaign End Date'
export const CONTRACTED_IMPRESSION = 'Contracted Impressions'
export const IMPRESSION = 'Impressions (Delivered)'
export const ACTUALIZED_VENDOR_SPEND = 'Actualized Vendor Spend'
export const DASHBOARD_DAYS_LIVE = 'Days Live'
export const PACING_PERCENTAGE = 'Pacing %'
export const REACH = 'Reach'
export const DASHBOARD_FREQUENCY = 'Frequency'
export const INFLUENCED_SALES = 'Influenced Sales'
export const ROAS = 'ROAS'

export const REMAINING = 'Days Remaining'
export const COMPLETED_CAMPAIGNS = 'Completed Campaigns'
export const LIVE_AND_COMPLETED_CAMPAIGNS = 'Live & Completed Campaigns'
export const COMPLETED_CAMPAIGN_START = 'Campaign Start Date'
export const COMPLETED_CAMPAIGN_END = 'Campaign End Date'
export const DAYS_LIVE = 'Days Live'
export const DAYS_REMAINING = 'Days Remaining'
export const TOTAL_SPEND = 'Total Spend'
export const TOTAL_BUDGET = 'Total Budget'
export const DASHBOARD_BUDGET = 'Campaign Budget'
export const CUSTOMIZE = 'Customize'

/* Saved Views Page */
export const VIEW_NAME = 'View Name'
export const CREATED_BY = 'Created By'
export const EXPORTED_BY = 'Exported By'
export const EXPORTED_ON = 'Exported On'
export const CLOUD_EXPORTED_FILES = 'Cloud Exports'
export const MODIFIED_BY = 'Modified By'
export const CREATED_DATE = 'Date Created'
export const DATE_MODIFIED = 'Date Modified'
export const REPORT_TYPE = 'Report Type'
export const CAMPAIGNS = 'Campaigns'
export const TABLES = 'Tables'
export const CHARTS = 'Charts'
export const VISUALS = 'Visuals'
export const FILTER_BY = 'Filter By...'

/* Templates */
export const REPORT_TEMPLATES = 'Report Templates'
export const TABLE_TEMPLATES = 'Table Templates'
export const GLOBAL_TEMPLATES = 'Global Templates'
export const TEMPLATE_NAME = 'Template Name'
export const PREFERRED_TEMPLATE = 'Preferred Template'

/* Schedule Report */
export const SCHEDULE = 'Schedule'
export const UPDATE_SCHEDULE = 'Update Schedule'
export const SUBJECT = 'Subject/Report Name'
export const SCHEDULE_REPORT = 'Schedule Report'
export const SCHEDULE_REPORT_DESCRIPTION =
  'Select how, where, and when you would like your Report View sent. You can view and edit your Scheduled Reports from the Dashboard. All reports are sent by 9am CST.'
export const EMAIL_PLACEHOLDER = 'Please enter recipient email addresses'
export const FREQUENCY = 'Frequency'
export const ON = 'On:'
export const FROM = 'From'
export const UNTIL = 'Until'
export const STATUS = 'Status'
export const ONCE = 'ONCE'
export const DAILY = 'DAILY'
export const WEEKLY = 'WEEKLY'
export const MONTHLY = 'MONTHLY'
export const ONCE_LABEL = 'Once'
export const DAILY_LABEL = 'Daily'
export const WEEKLY_LABEL = 'Weekly'
export const MONTHLY_LABEL = 'Monthly'
export const MONDAY = 'Monday'
export const TUESDAY = 'Tuesday'
export const WEDNESDAY = 'Wednesday'
export const THURSDAY = 'Thursday'
export const FRIDAY = 'Friday'
export const SATURDAY = 'Saturday'
export const SUNDAY = 'Sunday'
export const REPORT_NAME = 'Report Name'
export const DATE_CREATED = 'Date Created'
export const FORMAT = 'Format'
export const EDIT = 'Edit'
export const PAUSE = 'Pause'
export const RESUME = 'Resume'

/* Metrics Manager */
export const METRICS_BUILDER = 'Metric Builder'
export const SAVED_CUSTOM_METRICS = 'Custom Metrics'
export const STANDARD_METRICS = 'Standard Metrics'
export const BUILDER_TITLE = 'Custom Metric'
export const GLOBAL_CUSTOM_METRICS = 'Global Custom Metrics'
export const BUILDER_DESCRIPTION =
  'Build the custom metric by adding the folmula below.'
export const SELECT_TABLE = 'Select Table Type'
export const METRICS_FORMULA = 'Metric Formula Editor'
export const METRICS_NAME = 'Metric Name'
export const METRIC_NAME = 'METRIC NAME'
export const SELECT_METRICS_LABEL = 'Select Metrics'
export const SELECT_FORMAT = 'Select Format'
export const SELECT_AGGREGATE_LABEL = 'Select Operator'
export const CREATE_METRICS = 'Create New Metric'
export const UPDATE_METRICS = 'Update Metric'
export const METRIC = 'Metric'
export const BACKSPACE = 'BACK SPACE'
export const DESCRIPTION = 'Description'

/* View Page - Overview */
export const OBJECTIVE = 'Objective'
export const BUDGET = 'Campaign Budget'
export const AUDIENCE = 'Audience'
export const TOTAL_IMPRESSIONS = 'Total Impressions'
export const OVERVIEW = 'Overview'
export const DATA_TABLES = 'Data Tables'
export const DATA_VISUALIZATIONS = 'Data Visualizations'
export const DELETE_VIEW = 'Delete View'

/* View Page - Table Builder */
export const PRIMARY_LABEL = 'Primary Dimension'
export const ROW_LABEL = 'Row Dimension'
export const PR_LABEL = 'Primary Dimension'
export const ADD_TABLE = 'Add Table'
export const APPLY_FILTERS = 'Apply Filters'

/* Visualizations */
export const ADD_VISUALIZATION = 'Add Visualization'
export const TEMPLATE = 'Template:'
export const MULTIAXIS = 'Multi-Axis Bar-Line Chart'
export const MULTIAXIS_LINE_CHART = 'Multi-Axis Line Chart'
export const PERCENTAGE_CHART = 'Percentage Bar Chart'
export const PERCENTAGE_ALERT_MSG =
  'Please select metrics & primary dimension to view chart'

/* View Page - Export Modal */
export const EXPORT_MODAL_TITLE =
  'Select which objects you would like to export'
export const EXPORT_AS = 'Export Table As:'
export const EXPORT = 'Export'
export const EXPORT_FORMAT_CSV = 'CSV (Flattened)'
export const EXPORT_FORMAT_CSV_VALUE = 'CSV'
export const EXPORT_FORMAT_EXCEL = 'EXCEL'
export const EXPORT_FORMAT_LINK = 'Link to Report in Kiosk'
export const EXPORT_FORMAT_LINK_VALUE = 'LINK'

/* View Page - Export to Cloud Modal */
export const AMAZON_S3 = 'Amazon S3'
export const AMAZON = 'AMAZON'
export const GOOGLE = 'GOOGLE'
export const AZURE = 'AZURE'
export const EMAIL = 'EMAIL'
export const EMAIL_CLOUD = 'Email'
export const GOOGLE_CLOUD_PLATFORM = 'Google Cloud Platform'
export const AZURE_CLOUD_PLATFORM = 'Azure Cloud Platform'
export const TARGET_CLOUD = 'Target Cloud'
export const TOSS = 'TOSS'
export const SEND_TO_CLOUD = 'Send Report to Email or Cloud'
export const SELECT_WHERE_TO_EXPORT =
  'Select how and where you would like to have your Report View sent'
export const SEND_REPORT_AS = 'Send Report as:'
export const AMAZON_S3_INFO = 'Amazon S3 information:'
export const ACCESS_KEY = 'Access Key'
export const SECRET_KEY = 'Secret Key'
export const BUCKET_NAME = 'Bucket Name'
export const REGION = 'Region'

export const S3_URL = 'URL'
export const GCP_INFO = 'Google Cloud Platform information:'
export const TYPE = 'Type'
export const PRIVATE_KEY_ID = 'Private Key Id'
export const PRIVATE_KEY = 'Private Key'
export const CLIENT_EMAIL = 'Client Email'
export const CLIENT_ID = 'Client Id'
export const ADD_RECIPIENTS = 'Add Recipients:'
export const AZURE_INFO = 'Azure Cloud Platform information:'
export const ACCOUNT_NAME = 'Account Name'
export const ACCOUNT_KEY = 'Account Key'
export const INTERNAL_VIEW_EMAIL_ALERT =
  'You are attempting to send a report built on an internal-only view to external email address(es). Please remove them to proceed'
export const INTERNAL_VIEW_CLOUD_EXPORT_ALERT =
  'You are attempting to send a report built on an internal-only view to an external cloud setup'

/* View Page - Save Template */
export const REPORT_TEMPLATE_TXT = `This will save table as template for future use. You can access and open saved views from Templates > Report Templates menu on your dashboard`
export const TABLE_TEMPLATE_TXT = `This will save table as template for future use. You can access and open saved tables from Templates > Table Templates menu on your dashboard`
export const TABLE_TITLE = 'Save Table Template'
export const REPORT_TITLE = 'Save Report Template'
export const CREATE_NEW_TEMPLATE = 'CREATE A NEW TEMPLATE'
export const CREATE_NEW_METRIC = 'CREATE A NEW METRIC'
export const SAVE_AND_OVERWRITE = 'Save & Overwrite'
export const CREATE_NEW_REPORT = 'CREATE A NEW REPORT'
export const SHARE_TEMPLATE = 'Share This Template'
export const SHARE_METRIC = 'Share This Metric'
export const SET_DEFAULT_DESCRIPTION =
  'Set this template as the deafult template for the selected account(s) below. This will also share the unique copy of this template with those accounts.'
export const SET_DEFAULT_TEMPLATE_NOTES =
  'Setting this template as default for selected accounts will override any existing default setting defined by template admin for those accounts.'
export const GLOBAL_TEMPLATE_SHARE_NOTES =
  'Any edits you make to this template will also be updated in the shared template. If you delete this template, it will also be deleted from the selected accounts.'
export const GLOBAL_TEMPLATE_SHARE_DESCRIPTION =
  'Share this template with the selected account(s) below. The template will appear in their list as follows:'
export const SHARE_TEMPLATE_DESCRIPTION =
  'Share a new copy of this template with the selected account(s) below. The template will appear in their list as follows:'
export const SHARE_METRIC_DESCRIPTION =
  'Share a new copy of this metric with the selected account(s) below. The metric will appear in their list as follows:'
export const SHARE_TEMPLATE_NOTES =
  'Any edits you make to this template after sharing will NOT be updated in the shared copies. If you delete this template, it will still exist for the other accounts.'
export const SHARE_METRIC_NOTES =
  'Note: Any edits you make to this metric after sharing will NOT be updated in the shared copies. If you delete this metric, it will still exist for the other accounts.'
export const SHARE_COPY = 'Share a Copy'

/* Account Selection Modal */
export const SELECT_ACCOUNT = 'Select Account'
export const CHOOSE_ACCOUNT =
  'Choose which account you would like to open to view reporting data'
export const CHOOSE_ACCOUNT_NOTICE =
  'NOTICE: Any unsaved work will be lost when switching to a different account.'
export const VIEW_THIS_ACCOUNT = 'View this account'
export const ACCESS_RIK_INFO =
  'Roundel Kiosk can be accessed only when you are connected to VPN'

/* Feedback form constants */
export const KIOSK_FEEDBACK = 'Kiosk Feedback'
export const FEEDBACK_QUESTION_1 =
  'Do you have any enhancement ideas that would help improve the Kiosk experience?'
export const FEEDBACK_QUESTION_2 =
  'Please rate your experience using Kiosk to get your task done'

/* Application Level */
export const APP_NAME = 'Roundel Insights Kiosk'
export const POL_DOMAIN = 'partnersonline.com'

/* Admin Page */
export const ALERTS = 'ALERTS'
export const UNIVERSAL_ALERT_TITLE = 'Universal Alert Message'
export const UNIVERSAL_ALERT = 'Universal Alert'
export const ORGANIZATION_NAME = 'Organization Name'
export const BUSINESS_PARTNER_ID = 'Business Partner Id / Vendor Id'
export const CRITEO_ACCOUNTS = 'Criteo Accounts'
export const VENDOR_TYPE = 'Vendor Type'

export const USER_MAPPING = 'User Mapping'
export const UPSELL_QUALIFIER = 'Incremental Investment Qualifier'
export const USER_ID = 'User Id'
export const INVALID_VIEW_ACCESS_MSG =
  'Report access is denied for one or more campaigns in your selection. Please review your access permissions or campaign selection to proceed with viewing this report.'
export const INVALID_VIEW_ACCESS_TITLE = 'Access Restricted'

export const VENDOR_MAPPING = 'Vendor Mapping'
export const LEVEL_OF_SERVICE = 'Level of Service'
export const VENDOR_NAME = 'Vendor Name'
