import { useQuery } from '@tanstack/react-query'
import campaignSearch, {
  FieldGroup,
  SearchParameters,
} from '../api/campaign/search'
import { getSelectedAccount } from '../common/helper'
import { VENDOR } from '../constants'
import { validateCampaignSearchParameters } from './helper'

const useCampaignSearch = (
  fieldGroup: FieldGroup,
  parameters: SearchParameters = {} as SearchParameters,
) => {
  const vendorDetails = getSelectedAccount()
  if (fieldGroup !== VENDOR && vendorDetails?.id) {
    parameters = {
      ...parameters,
      bpId: vendorDetails?.id,
    }
  }
  // stop other api calling when we have invalid parameters
  const enabled = validateCampaignSearchParameters(fieldGroup, parameters)
  return useQuery<any, any, any, [FieldGroup, SearchParameters]>(
    [fieldGroup, parameters],
    ({ queryKey }) => campaignSearch.apply(null, queryKey),
    { enabled },
  )
}

export default useCampaignSearch
