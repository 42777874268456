import { useQuery } from '@tanstack/react-query'
import getSharepointData from '../api/sharepoint/'
import { GET_UNIVERSAL_ALERT_KEY } from '../constants'

const useGetUniversalStatus = (config_name: string) => {
  return useQuery([GET_UNIVERSAL_ALERT_KEY], () =>
    getSharepointData(config_name),
  )
}

export default useGetUniversalStatus
