export interface MetricProperties {
  format: 'currency' | 'number' | 'percentage'
  decimal?: number
}
export interface Metric {
  displayName: string
  responseName: string
  format: MetricProperties
  type: string
  category: string
  reportType: string[]
}

export interface Dimension {
  displayName: string
  type: string
  reportType: string[]
  responseName: string
}

let dateFilters: string[]

export const getDateFilters = (dimensions: Record<string, any>) => {
  const filterValues = { ...dimensions }
  if (dateFilters) {
    return dateFilters
  }

  dateFilters = Object.keys(filterValues).reduce((acc, filter) => {
    if (filterValues[filter].type === 'date') {
      acc.push(filter)
    }
    acc.push()
    return acc
  }, [] as string[])

  return dateFilters
}
