import { Modal } from '@enterprise-ui/canvas-ui-react'
import styled from 'styled-components'

export const StyledAccountSelector = styled(Modal)`
  z-index: 99 !important;

  .C-Modal__container {
    max-width: 40vw;
  }

  .C-Autocomplete__Options {
    max-height: 125px;
  }

  h1.C-Heading {
    padding-left: var(--canvas-spacing--lg) !important;
    padding-top: var(--canvas-spacing--lg) !important;
  }

  .C-Modal__container__content {
    padding-bottom: var(--canvas-spacing--sm) !important;
  }
`

export const StyledFeedbackForm = styled(Modal)`
  .C-FormField__Label {
    font-size: 16px;
  }

  .C-Input--type-info.isErrored {
    display: none;
  }

  .C-Autocomplete__Options {
    max-height: 125px;
  }

  h1.C-Heading {
    padding-left: var(--canvas-spacing--lg) !important;
    padding-top: var(--canvas-spacing--lg) !important;
  }

  .C-Modal__container__content {
    padding-bottom: var(--canvas-spacing--sm) !important;
  }
`
