import {
  Anchor,
  GridContainer,
  Heading,
  Button,
  GridItem,
} from '@enterprise-ui/canvas-ui-react'
import { VIEW_PATH } from '../../constants'
import {
  getFromLocalStorage,
  removeLocalStorageItems,
} from '../../common/helper'
import { trackEvent } from '../../common/fireflyHelper'
import { useEffect } from 'react'
import { StyledDashboardBodyLayout } from './styles'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import logo from '../../assets/Roundel_Media_Logo_White.png'
import Header from '../Header'
/**
 * Component shown when aggregate call timesout
 */

interface Props {
  userDetails: Record<string, any>
}

const TimeoutScreen = (props: Props) => {
  const { userDetails } = props
  useEffect(() => {
    const payloadAggregation = getFromLocalStorage('aggregation_payload')
    trackEvent('Aggregation Request - Failure UI', {
      ...payloadAggregation,
      failureMessage: 'Failed while rendering the aggregate data ',
      module: 'view',
    })
    removeLocalStorageItems(['aggregation_payload'])
  }, [])

  return (
    <Layout fullWidth={true} theme="roundel" darkMode={false}>
      <Header userDetails={userDetails} />
      <StyledDashboardBodyLayout includeRail={true} className="hc-pa-none">
        <div className="hc-pa-normal hc-pa-2x hc-mt-3x Roundel__Gradient--vertical-blue-purple hc-full-height-error">
          <GridContainer className="hc-pa-none">
            <GridItem xs={12}>
              <div className="wrapper">
                <Heading className="hc-fs-md">
                  <div>
                    The saved view is requesting a particularly large amount of
                    data that exceeds Kiosk maximums. Please request data for
                    fewer campaigns, or dimensions, or metrics.
                  </div>
                </Heading>
                <Heading className="buttonContainer">
                  <Button
                    className="hc-ba-min hc-mt-sm buttonLink"
                    data-test="create-new-metric-btn"
                    type="primary"
                  >
                    <Anchor href={VIEW_PATH}>Back to New Report</Anchor>
                  </Button>
                </Heading>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </StyledDashboardBodyLayout>
    </Layout>
  )
}

export default TimeoutScreen
