import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import { TOASTER_TIMEOUT } from '../../config/appConfig'
import { StyledToast } from './styles'

interface Prop {
  type?: 'alert' | 'error' | 'success'
  message: string
  onRefuse?: Function
  onComplete?: Function
  hideDuration?: number
}
const Toast = (prop: Prop) => {
  const {
    type,
    message,
    onRefuse,
    onComplete,
    hideDuration = TOASTER_TIMEOUT,
  } = prop
  const makeToast = ToastProvider.useToaster()
  useEffect(() => {
    makeToast({
      type: type,
      message: message,
      heading: type,
      autoHideDuration: hideDuration,
      onComplete: onComplete,
      onRefuse: onRefuse,
    })
  }, [makeToast, type, message, hideDuration, onComplete, onRefuse])

  return <StyledToast></StyledToast>
}

export default Toast
