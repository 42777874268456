import {
  Button,
  FormField,
  GridContainer,
  GridItem,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { ChangeEvent, useEffect, useState } from 'react'
import { isLoggedInAsInternalUser } from '../../common/helper'
import {
  SUBMIT,
  CANCEL,
  KIOSK_FEEDBACK,
  FEEDBACK_QUESTION_1,
  FEEDBACK_QUESTION_2,
} from '../../constants'
import useSaveFeedback from '../../domain/useSaveFeedback'
import { StyledFeedbackForm } from './styles'
import { trackEvent } from '../../common/fireflyHelper'
interface Props {
  isVisible: boolean
  onCancel: Function
  onSubmit: Function
  from?: string
  feedbackUpdate?: boolean
}

interface FormFields {
  answer1: string
  answer2: string
  team: string
}

const teamOptions = [
  {
    value: 'PSG',
    label: 'PSG',
  },
  {
    value: 'PANDI',
    label: 'P&I',
  },
  {
    value: 'TANDO',
    label: 'T&O',
  },
  {
    value: 'MEDIA_PLANNING',
    label: 'Media Planning',
  },
  {
    value: 'PRODUCT',
    label: 'Product',
  },
  {
    value: 'GTM',
    label: 'GTM',
  },
  {
    value: 'BII',
    label: 'BII',
  },
  {
    value: 'OTHERS',
    label: 'Others',
  },
]

const defaultFormFields = {
  answer1: '',
  answer2: '',
  team: 'PSG',
}

const FeedbackForm = (props: Props) => {
  const { isVisible, onCancel, onSubmit, from = '' } = props
  const [formFields, setFormField] = useState<FormFields>(defaultFormFields)
  const [isFormValid, setFormValidation] = useState<boolean>(false)

  const { mutateAsync: saveFeedback } = useSaveFeedback()

  const isAnswerValid = (answer: string) => {
    const pattern = /^[ A-Za-z0-9_!%$?.,()\n*=+;'/\\&":-]{1,2000}$/
    return answer && pattern.test(answer)
  }

  useEffect(() => {
    const { answer1, team } = formFields
    if (isAnswerValid(answer1) && team) {
      setFormValidation(true)
    } else {
      setFormValidation(false)
    }
  }, [formFields])

  const onCancelBtnclick = () => {
    setFormField({ ...formFields, answer1: '', answer2: '' })
    onCancel()
  }

  let data: any = <></>
  if (!from && from !== 'popup') {
    data = (
      <div className="hc-pa-normal hc-ph-lg hc-pb-lg">
        <GridContainer className="hc-pb-sm">
          <GridItem xs={12}>
            <FormField
              id={FEEDBACK_QUESTION_1}
              data-test="feedbackQuestion1"
              className="hc-mb-md hc-mt-md"
              type="textarea"
              label={FEEDBACK_QUESTION_1}
              value={formFields.answer1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setFormField({ ...formFields, answer1: value })
              }}
              error={!isAnswerValid(formFields.answer1)}
            />
            <Input.Label htmlFor="feedbackQuestion2">
              {FEEDBACK_QUESTION_2}
            </Input.Label>
            <Input.Rating
              id="feedbackQuestion2"
              data-test="feedbackQuestion2"
              onClick={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setFormField({ ...formFields, answer2: value })
              }}
              size="expanded"
              total={5}
              error={!isAnswerValid(formFields.answer2)}
              className={`hc-mb-md`}
            />
            {isLoggedInAsInternalUser() && (
              <FormField
                id="team_select"
                type="select"
                data-test="internal_team_select"
                options={teamOptions}
                label="Please select your team"
                value={formFields.team}
                onUpdate={(id: number, value: string) => {
                  setFormField({ ...formFields, team: value })
                }}
              />
            )}
          </GridItem>
        </GridContainer>
        <GridContainer
          direction="row-reverse"
          spacing="dense"
          className="hc-mt-lg"
        >
          <GridItem>
            <Button
              type="primary"
              disabled={!isFormValid}
              data-test="feedbackSubmit"
              onClick={() => {
                const team_name = isLoggedInAsInternalUser()
                  ? formFields.team
                  : undefined
                const requestObject = {
                  question_answer_list: [
                    {
                      question: FEEDBACK_QUESTION_1,
                      answer: formFields.answer1,
                    },
                  ],
                  team_name,
                }
                let ratingQuestion
                if (formFields.answer2) {
                  ratingQuestion = {
                    question: FEEDBACK_QUESTION_2,
                    answer: formFields.answer2,
                  }
                  requestObject.question_answer_list.push(ratingQuestion)
                }
                saveFeedback(requestObject)
                  .then(() => {
                    onSubmit('success')
                    trackEvent(`Menu- Feedback Submit`, {
                      key: `Feedback Form`,
                      module: 'Menu',
                    })
                  })
                  .catch(() => {
                    onSubmit('error')
                  })
                onCancelBtnclick()
              }}
            >
              {SUBMIT}
            </Button>
          </GridItem>
          <GridItem>
            <Button onClick={onCancelBtnclick}>{CANCEL}</Button>
          </GridItem>
        </GridContainer>
      </div>
    )
  } else {
    data = (
      <div className="hc-pa-normal hc-ph-lg hc-pb-lg">
        <GridContainer className="hc-pb-sm">
          <GridItem xs={12}>
            <Input.Label htmlFor="feedbackQuestion2">
              {FEEDBACK_QUESTION_2}
            </Input.Label>
            <Input.Rating
              id="feedbackQuestion2"
              data-test="feedbackQuestion2"
              onClick={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setFormField({ ...formFields, answer2: value })
              }}
              size="expanded"
              total={5}
              error={!isAnswerValid(formFields.answer2)}
              className={`hc-mb-md`}
            />
            <FormField
              id={FEEDBACK_QUESTION_1}
              data-test="feedbackQuestion1"
              className="hc-mb-md hc-mt-md"
              type="textarea"
              label={FEEDBACK_QUESTION_1}
              value={formFields.answer1}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const value = event.target.value
                setFormField({ ...formFields, answer1: value })
              }}
              error={!isAnswerValid(formFields.answer1)}
            />
            {isLoggedInAsInternalUser() && (
              <FormField
                id="team_select"
                type="select"
                data-test="internal_team_select"
                options={teamOptions}
                label="Please select your team"
                value={formFields.team}
                onUpdate={(id: number, value: string) => {
                  setFormField({ ...formFields, team: value })
                }}
              />
            )}
          </GridItem>
        </GridContainer>
        <GridContainer
          direction="row-reverse"
          spacing="dense"
          className="hc-mt-lg"
        >
          <GridItem>
            <Button
              type="primary"
              disabled={!isFormValid}
              data-test="feedbackSubmit"
              onClick={() => {
                const team_name = isLoggedInAsInternalUser()
                  ? formFields.team
                  : undefined
                const requestObject = {
                  question_answer_list: [
                    {
                      question: FEEDBACK_QUESTION_1,
                      answer: formFields.answer1,
                    },
                  ],
                  team_name,
                }
                let ratingQuestion
                if (formFields.answer2) {
                  ratingQuestion = {
                    question: FEEDBACK_QUESTION_2,
                    answer: formFields.answer2,
                  }
                  requestObject.question_answer_list.push(ratingQuestion)
                }
                saveFeedback(requestObject)
                  .then(() => {
                    onSubmit('success')
                  })
                  .catch(() => {
                    onSubmit('error')
                  })
                onCancelBtnclick()
              }}
            >
              {SUBMIT}
            </Button>
          </GridItem>
          <GridItem>
            <Button onClick={onCancelBtnclick}>{CANCEL}</Button>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  if (from !== 'popup') {
    data = (
      <StyledFeedbackForm
        headingText={KIOSK_FEEDBACK}
        onRefuse={onCancelBtnclick}
        isVisible={isVisible}
      >
        {data}
      </StyledFeedbackForm>
    )
  }
  return <div>{data}</div>
}

export default FeedbackForm
