const TOASTER_TIMEOUT = 10000
const POL_PROD_SUPPORT_LINK = 'https://www.partnersonline.com/'
const POL_DEV_SUPPORT_LINK = 'https://xyc.partnersonline.com/'
const AMAZON_STANDARD_URL = 'https://s3.amazonaws.com'
const FEEDBACK_FORM_URL = 'https://forms.office.com/r/MtKUd0t1ZA'
const TERMS_OF_USE_DEFAULT_LINK =
  'sites/ogrp-tts-polapplications/Pages/3649.aspx'

export {
  AMAZON_STANDARD_URL,
  TOASTER_TIMEOUT,
  POL_PROD_SUPPORT_LINK,
  POL_DEV_SUPPORT_LINK,
  FEEDBACK_FORM_URL,
  TERMS_OF_USE_DEFAULT_LINK,
}
