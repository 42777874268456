import { trackEvent } from './fireflyHelper'
import { getSelectedAccount } from './helper'

const getAccountInfo = () => {
  const selectedAccount = getSelectedAccount() || { id: '', label: '' }
  return {
    accountId: selectedAccount.id.toString(),
    accountName: selectedAccount.label,
  }
}

const trackLogin = () => trackEvent('Logon', getAccountInfo())

const trackLogOut = () => trackEvent('Logout', getAccountInfo())

export { trackLogin, trackLogOut }
