import { logEvent, LogLevel } from '@praxis/component-logging'

export interface ErrorObject extends Object {
  info?: string
  isAxiosError?: boolean
  toJSON?: () => object
}
export interface LoggingInfo {
  message: string | Event
  url?: string
  lineNumber?: number
  columnNumber?: number
  error?: ErrorObject | string
}
export const log = {
  error: (loggingInfo: LoggingInfo): void => {
    logEvent(loggingInfo, { level: LogLevel.Error })
  },
  info: (loggingInfo: LoggingInfo): void => {
    logEvent(loggingInfo, { level: LogLevel.Info })
  },
  warn: (loggingInfo: LoggingInfo): void => {
    logEvent(loggingInfo, { level: LogLevel.Warn })
  },
}
