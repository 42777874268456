/* Query Keys*/
const GET_CAMPAIGN_OVERVIEW_KEY = 'GET_CAMPAIGN_OVERVIEW_KEY'
const GET_DASHBOARD_OVERVIEW_KEY = 'GET_DASHBOARD_OVERVIEW_KEY'
const GET_NOTIFICATIONS_KEY = 'GET_NOTIFICATIONS_KEY'
const GET_USER_TYPE = 'GET_USER_TYPE'
const GET_SAVED_VIEWS_KEY = 'GET_SAVED_VIEWS_KEY'
const GET_PINNED_VIEW_KEY = 'GET_PINNED_VIEW_KEY'
const GET_TEMPLATES_KEY = 'GET_TEMPLATES_KEY'
const GET_TEMPLATES_CHECK_DUPLICATE_KEY = 'GET_TEMPLATES_CHECK_DUPLICATE_KEY'
const GET_SCHEDULED_REPORTS_KEY = 'GET_SCHEDULED_REPORTS_KEY'
const GET_SAVED_METRICS_KEY = 'GET_SAVED_METRICS_KEY'
const GET_SCHEDULED_REPORT_DETAILS_KEY = 'GET_SCHEDULED_REPORT_DETAILS_KEY'
const GET_METRIC_DETAILS_KEY = 'GET_METRIC_DETAILS_KEY'
const GET_COMMON_METRICS_KEY = 'GET_COMMON_METRICS_KEY'
const GET_REPORT_AND_SUBTYPES_KEY = 'GET_REPORT_AND_SUBTYPES_KEY'
const GET_CAMPAIGN_CATEGORY_KEY = 'GET_CAMPAIGN_CATEGORY_KEY'
const GET_VIEW_BY_ID_KEY = 'GET_VIEW_BY_ID_KEY'
const GET_TEMPLATE_BY_ID_KEY = 'GET_TEMPLATE_BY_ID_KEY'
const GET_BUCKET_DETAILS_KEY = 'GET_BUCKET_DETAILS_KEY'
const GET_CAMPAIGN_METADATA_KEY = 'GET_CAMPAIGN_METADATA_KEY'
const GET_MEDIA_NAME_METADATA_KEY = 'GET_MEDIA_NAME_METADATA_KEY'
const GET_DIMENSIONS_METADATA_KEY = 'GET_DIMENSIONS_METADATA_KEY'
const GET_CAMPAIGN_STATUS_KEY = 'GET_CAMPAIGN_STATUS_KEY'
const GET_PRE_AGGREGATE_ROW_COUNT_KEY = 'GET_PRE_AGGREGATE_ROW_COUNT_KEY'
const GET_DIMENSIONS_KEY = 'GET_DIMENSIONS_KEY'
const GET_ACCOUNT_METRICS_KEY = 'GET_ACCOUNT_METRICS_KEY'
const LOAD_KEY = 'LOAD_KEY'
const GET_EMAIL_PREFERENCE_KEY = 'GET_EMAIL_PREFERENCE_KEY'
const GET_EXPORTED_FILES_KEY = 'GET_EXPORTED_FILES_KEY'
const GET_UNIVERSAL_ALERT_KEY = 'GET_UNIVERSAL_ALERT_KEY'
const GET_ALL_VENDORS_KEY = 'GET_ALL_VENDORS_KEY'
const GET_ALL_CRITEO_ACCOUNTS_KEY = 'GET_ALL_CRITEO_ACCOUNTS_KEY'
const GET_USER_MAPPINGS_KEY = 'GET_USER_MAPPINGS_KEY'
const GET_UPSELL_QUALIFIER_KEY = 'GET_UPSELL_QUALIFIER_KEY'
const GET_VENDOR_MAPPINGS_KEY = 'GET_VENDOR_MAPPINGS_KEY'
const GET_SALESFORCE_ACCOUNTS = 'GET_SALESFORCE_ACCOUNTS'
const GET_AWS_REGION_KEY = 'GET_AWS_REGION_KEY'

export {
  GET_CAMPAIGN_OVERVIEW_KEY,
  GET_DASHBOARD_OVERVIEW_KEY,
  GET_NOTIFICATIONS_KEY,
  GET_SAVED_VIEWS_KEY,
  GET_PINNED_VIEW_KEY,
  GET_TEMPLATES_KEY,
  GET_SCHEDULED_REPORTS_KEY,
  GET_SAVED_METRICS_KEY,
  GET_SCHEDULED_REPORT_DETAILS_KEY,
  GET_METRIC_DETAILS_KEY,
  GET_TEMPLATES_CHECK_DUPLICATE_KEY,
  GET_COMMON_METRICS_KEY,
  GET_REPORT_AND_SUBTYPES_KEY,
  GET_VIEW_BY_ID_KEY,
  GET_TEMPLATE_BY_ID_KEY,
  GET_BUCKET_DETAILS_KEY,
  GET_CAMPAIGN_METADATA_KEY,
  GET_MEDIA_NAME_METADATA_KEY,
  GET_CAMPAIGN_STATUS_KEY,
  GET_DIMENSIONS_KEY,
  GET_ACCOUNT_METRICS_KEY,
  LOAD_KEY,
  GET_EMAIL_PREFERENCE_KEY,
  GET_EXPORTED_FILES_KEY,
  GET_DIMENSIONS_METADATA_KEY,
  GET_UNIVERSAL_ALERT_KEY,
  GET_CAMPAIGN_CATEGORY_KEY,
  GET_ALL_VENDORS_KEY,
  GET_ALL_CRITEO_ACCOUNTS_KEY,
  GET_USER_MAPPINGS_KEY,
  GET_UPSELL_QUALIFIER_KEY,
  GET_USER_TYPE,
  GET_VENDOR_MAPPINGS_KEY,
  GET_SALESFORCE_ACCOUNTS,
  GET_AWS_REGION_KEY,
  GET_PRE_AGGREGATE_ROW_COUNT_KEY,
}
