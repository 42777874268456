import styled from 'styled-components'
import { Layout } from '@enterprise-ui/canvas-ui-react'

export const StyledFallBackUI = styled.div`
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: center;

    .C-Heading {
      text-align: center;
    }
    .buttonLink:hover a {
      color: #fff;
      text-decoration: none;
    }
  }
`

export const StyledDashboardBodyLayout = styled(Layout.Body)`
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 720px;
    background: #fff;
    justify-content: center;
    box-shadow:
      0 0 6px 6px rgb(51 51 51 / 4%),
      0 6px 10px 0 rgb(51 51 51 / 16%);
    padding: 30px;
    border-radius: 8px;
  }

  .error-text {
    width: 100%;
  }

  .C-Heading {
    width: 100%;
  }

  .buttonLink:hover a,
  .buttonLink a {
    color: #fff;
    text-decoration: none;
  }

  .buttonContainer {
    text-align: right;
    margin-top: 10px;
  }

  .messageHeader {
    margin-bottom: 10px;
  }

  .buttonSupport:hover a {
    color: #fff;
    text-decoration: none;
  }
`
