import styled from 'styled-components'

export const StyledUniversalAlert = styled.div`
  position: fixed;
  z-index: 1001;
  right: 12px;
  top: 12px;
  padding-top: 56px !important;
  .alert-icon {
    color: #fff;
    left: -26px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .alert-container {
    border-left: 36px solid #9798ff;
    display: flex;
    background: #fff;
    margin-bottom: 20px;
    max-width: 500px;
    position: relative;
    width: 500px;
    box-shadow:
      0 0 0 1px rgb(51 51 51 / 4%),
      0 24px 24px -8px rgb(0 0 0 / 12%),
      0 12px 16px -8px rgb(0 0 0 / 16%);
    right: 12px;

    .alert-content-section {
      padding: 12px;
      margin: 0 auto;
      width: 100%;
      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.25;
      }
      p {
        padding-top: 0;
        font-size: 12px;
        margin-bottom: 0 !important;
        a {
          color: #6663d9;
          font-weight: bold;
          text-decoration: underline;
        }
      }
      .action {
        text-align: right;
      }
    }
  }
`
