/* Common Alerts */
export const NO_DATA = 'No Data Available'
export const NO_ALERTS_TO_DISPLAY = 'No alerts to display.'
export const NOT_FOUND_404 = 'Selected resource not found.'
export const BAD_REQUEST = 'There was an error in the request made.'
export const DELETE_CONFIRMATION_TXT = 'Are you sure?'
export const GENERIC_ERROR_500 =
  'We were unable to process your request. Please try again, or contact the Roundel Kiosk Team if the problem persists.'
export const GENERIC_ERROR_503 =
  'The service is currently unavailable.  Please try again, or contact the Roundel Kiosk Team if the problem persists.'
export const FORBIDDEN_ERROR =
  'You do not have permission to access this data. Please contact the Roundel Kiosk Team if you believe this is an error.'
export const PRIMARY_ERROR_MSG =
  'Add a primary Dimension above to generate the data table'
export const BENCHMARK_ERROR_MSG =
  'Select Media Id or Media Name or Product Name and Campaign Name as a Dimension, to view either Product Benchmarks or Platform Benchmarks. Platform Benchmarks can also be viewed by selecting Platform Name and Campaign Name.'

/* Views */
export const VIEW_NAME_LENGTH_ERROR = 'Name must be 3 to 100 characters.'
export const VIEW_NAME_INVALID_ERROR = 'Please enter a valid name.'
export const SPECIAL_CHARS_TABLE =
  'Special characters are not allowed in a table name'
export const SPECIAL_CHARS_VISUALIZATION =
  'Special characters are not allowed in a visualization name'
export const VIEW_ALREADY_EXISTS = 'View already exists with the same name.'
export const NAME_ERROR_MSG =
  'Name must be more than 3 and not more than 31 characters'
export const TABLE_EXISTS = 'This table name already exists!'

/* Templates */
export const NO_TEMPLATE_SELECTED = 'No Template Selected'
export const LOCK_CONFIRMATION_TXT =
  'Are you sure you want to lock this template from being modified?'
export const UNLOCK_CONFIRMATION_TXT =
  'Are you sure you want to unlock this template?'
export const TEMPLATE_ALREADY_EXISTS =
  'Template already exists with the same name'
export const SAVED_TEMPLATE_EXISTS =
  'A template already exists with that name. Saving now will overwrite this template with your new template. Would you like to continue?'

/* Metrics */
export const METRICS_ALREADY_EXISTS = 'Metric already exists with same name'
export const METRICS_FORMULA_ERROR =
  'Bad request. Invalid function or metric in expression'
export const SUCCESS_METRICS_CREATE_MSG = 'Created Metric Successfully.'
export const SUCCESS_METRICS_UPDATE_MSG = 'Updated Metric Successfully.'
export const LOCK_METRIC_CONFIRMATION_TXT =
  'Are you sure you want to lock this metric from being modified?'
export const UNLOCK_METRIC_CONFIRMATION_TXT =
  'Are you sure you want to unlock this metric?'
export const METRIC_ALREADY_EXISTS = 'Metric already exists with the same name'

/* Schedule */
export const NO_SCHEDULED_REPORTS = 'You have no scheduled reports.'
export const SUBJECT_NAME_LENGTH_ERROR = 'Subject must be 3 to 100 characters.'

/* Admin */
export const USER_ID_LENGTH_ERROR = 'User Id must be 3 to 20 characters.'
export const USER_ID_INVALID_ERROR = 'Please enter a valid User Id.'
export const VENDOR_NAME_LENGTH_ERROR =
  'Vendor name must be 2 to 100 characters.'
export const VENDOR_NAME_INVALID_ERROR = 'Please enter a valid vendor name.'
export const BUSINESS_PARTNER_ID_LENGTH_ERROR =
  'Business partner Id must be 1 to 10 digits.'
export const BUSINESS_PARTNER_ID_INVALID_ERROR =
  'Please enter a valid Business partner id.'
