import { StyledUniversalAlert } from './styles'
import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

interface Prop {
  data: string
  handleClick: () => void
}

const UniversalAlert = (prop: Prop) => {
  const { data, handleClick } = prop

  return (
    <StyledUniversalAlert>
      <div className="alert-container">
        <EnterpriseIcon icon={InfoIcon} className="alert-icon" />
        <div className="alert-content-section">
          <p dangerouslySetInnerHTML={{ __html: data }} />
          <div className="action">
            <Button type="secondary" size="dense" onClick={handleClick}>
              OKAY
            </Button>
          </div>
        </div>
      </div>
    </StyledUniversalAlert>
  )
}

export default UniversalAlert
